import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const callistoLogo = "images/" + this.props.data.image;
    const hakkimizda1 = this.props.data.hakkimizda1;
    const hakkimizda2 = this.props.data.hakkimizda2;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={callistoLogo}
                alt="Callisto 3D"
              />
            </div>
            <div className="nine columns main-col">
              <h2>Hakkımızda</h2>

              <p>{hakkimizda1}</p>
              <p>{hakkimizda2}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>İletişim</h2>
                  <p className="address">
                    <span>
                      {street}
                      <br />
                      {city} {state}, {zip}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
