import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;
    const matterport = this.props.data.matterport.map(function (matterport) {
      return (
        <div key={matterport.school}>
          <h3>{matterport.school}</h3>
          <p>{matterport.description}</p>
          <ul>
            <li>
              <p>
                <span>{matterport.dijitalkopya}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{matterport.erisilebilirlik}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{matterport.akillietiket}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{matterport.olcualma}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{matterport.vrdestek}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{matterport.webtrafik}</span>
              </p>
            </li>
          </ul>
        </div>
      );
    });

    const digitalshowroom = this.props.data.digitalshowroom.map(function (
      digitalshowroom
    ) {
      return (
        <div key={digitalshowroom.gallery}>
          <h3>{digitalshowroom.gallery}</h3>
          <p>{digitalshowroom.description}</p>
          <ul>
            <li>
              <p>
                <span>{digitalshowroom.urunlistesi}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{digitalshowroom.sanalmagaza}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{digitalshowroom.sanaldeneyim}</span>
              </p>
            </li>
            <li>
              <p>
                <span>{digitalshowroom.eticaretsitesi}</span>
              </p>
            </li>
            <li>
              <p>
                <span></span>
              </p>
            </li>
          </ul>
        </div>
      );
    });

    const gisservices = this.props.data.gisservices.map(function (gisservices) {
      return (
        <div key={gisservices.cbs}>
          <h3>{gisservices.cbs}</h3>
          <p>{gisservices.description}</p>
        </div>
      );
    });

    const skills = this.props.data.skills.map((skills) => {
      const backgroundColor = this.getRandomColor();
      const className = "bar-expand " + skills.name.toLowerCase();
      const width = skills.level;

      return (
        <li key={skills.name}>
          <span style={{ width, backgroundColor }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Matterport</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{matterport}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Digital Showroom</span>
              </h1>
            </div>
            <div className="nine columns main-col">{digitalshowroom}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>CBS Çözümleri</span>
              </h1>
            </div>
            <div className="nine columns main-col">{gisservices}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Yeteneklerimiz</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
